import {
  Box,
  Button,
  Checkbox,
  Modal,
  Radio,
  TextField,
  Typography,
} from "@mui/material";
import * as Yup from "yup";

import close from "../../assets/Product/Close.svg";
import { useState } from "react";
import { useFormik } from "formik";

const validationSchema = Yup.object({
  awbCode: Yup.string().required("awb code is required"),
});
const TrackOrderModal = ({ open, onClose }) => {
  const [selected, setSelected] = useState("awb");

  const formFormik = useFormik({
    validationSchema,
    initialValues: {
      awbCode: "",
    },
    onSubmit: (values) => {
      console.log(values);
    },
  });
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        border: "none",
        borderRadius: 5,
      }}
    >
      <Box sx={style}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h6" component="h2">
            Track Order
          </Typography>
          <Box
            sx={{ cursor: "pointer" }}
            onClick={onClose}
            height={{ xs: 35, sm: 40, md: 40 }}
          >
            <img src={close} alt="" height={"100%"} />
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Radio
              name="selected"
              checked={selected === "awb"}
              onChange={(e) => {
                if (e.target.checked) {
                  setSelected("awb");
                } else {
                  setSelected("phone");
                }
              }}
            />
            <Typography variant="body2" component="p">
              AWB CODE
            </Typography>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Radio
              name="selected"
              checked={selected === "phone"}
              onChange={(e) => {
                if (e.target.checked) {
                  setSelected("phone");
                } else {
                  setSelected("awb");
                }
              }}
            />
            <Typography variant="body2" component="p">
              Phone Number
            </Typography>
          </Box>
        </Box>

        {/* <Box sx={{ display: "flex", marginY: "1rem", paddingX: "1rem" }}>
          <form
            onSubmit={formFormik.handleSubmit}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              gap: ".2rem",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Box>
                <TextField
                  label="Awb Code *"
                  placeholder="Enter Awb Code"
                  name="awbCode"
                  type="text"
                  value={formFormik.values.awbCode}
                  onChange={formFormik.handleChange}
                  onBlur={formFormik.handleBlur}
                  onWheel={(e) => e.target.blur()}
                />
                {formFormik.touched.awbCode && formFormik.errors.awbCode ? (
                  <Typography color={"#f32013"} sx={{ fontSize: ".8rem" }}>
                    {formFormik.errors.awbCode}
                  </Typography>
                ) : null}
              </Box>
            </Box>
            <Button variant="contained" type="submit">
              Submit
            </Button>
          </form>
        </Box> */}
      </Box>
    </Modal>
  );
};

const style = {
  width: { xs: "95%", sm: "60%", md: "40%" },
  bgcolor: "background.paper",
  borderRadius: 3,
  boxShadow: 24,
  overflow: "auto",
  p: 2,
};

export default TrackOrderModal;
