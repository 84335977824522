import React, { useEffect, useLayoutEffect, useState } from "react";

import QuickLinks from "./QuickLinks";
import logo from "../assets/Logo/Logo-2.svg";
import logoPng from "../assets/Logo/Logo.webp";
import close from "../assets/Product/Close.svg";
import search from "../assets/navbar/Search.svg";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { QuickLinksData } from "./QuickLinks";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";

import {
  AppBar,
  Box,
  Button,
  Collapse,
  Drawer,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  Paper,
  TextField,
  Toolbar,
  Typography,
  alpha,
  Link,
  Divider,
  CircularProgress,
  Alert,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { styled } from "@mui/material/styles";
import {
  matchPath,
  NavLink,
  Link as RouterLink,
  useLocation,
  useNavigate,
} from "react-router-dom";
import routes from "../routes/routes";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import wishlistIcon from "../assets/navbar/Wishlist.svg";
import cartIcon from "../assets/navbar/Cart.svg";
import profileIcon from "../assets/navbar/Profile.svg";
import menuIcon from "../assets/navbar/Menu.svg";
import phoneIcon from "../assets/navbar/phone.svg";
import ShoppingCartDrawerCard from "./Cards/ShoppingCartDrawerCard";
import { useDispatch, useSelector } from "react-redux";
import { logOut } from "../store/auth.slice";
import ShoppingDrawer from "./ShoppingDrawer";
import { useQuery } from "@tanstack/react-query";
import api from "../api/api";
import useDebounce from "../utils/hooks/useDebounce";

const Navbar = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [shoppingCartOpen, setShoppingCartMobileOpen] = React.useState(false);
  const [openIdx, setOpenIdx] = React.useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { isAuthenticated } = useSelector((state) => state.auth);
  const { cartSummary } = useSelector((state) => state.cart);

  const [searchedItems, setSearchItems] = useState([]);

  const location = useLocation();

  const [searchValue, setSearchValue] = useState("");
  const [isSearching, setIsSearching] = useState(false);
  const debouncedSearchValue = useDebounce(searchValue);

  const { data: profileData, isLoading: isProfileLoading } = useQuery({
    queryKey: ["profile"],
    queryFn: () => api.profile(),
    enabled: isAuthenticated,
  });

  const { data: categories, isLoading: isCategoriesLoading } = useQuery({
    queryKey: ["categories"],
    queryFn: () => api.getAllCategory().then((res) => res.data),
    staleTime: "Infinity",
    cacheTime: "Infinity",
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

  const {
    data: products,
    isLoading: isSearchResultLoading,
    isPending: isSearchResultPending,
  } = useQuery({
    queryKey: ["productsListing", debouncedSearchValue],
    queryFn: () =>
      api
        .getAllProducts(9093, 1, 30, "", debouncedSearchValue, "", "")
        .then((res) => res.data),
    enabled: debouncedSearchValue.length > 2,
  });
  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };
  const handleShoppingDrawerToggle = () => {
    setShoppingCartMobileOpen((prevState) => !prevState);
  };
  const handleDropdownClose = () => {
    setAnchorEl(null);
  };
  const handleLogout = () => {
    setAnchorEl(null);
    dispatch(logOut());
    navigate("/");
  };

  useEffect(() => {
    if (!products || products[0]?.status === 0) {
      setSearchItems([]);
    } else {
      setSearchItems(products);
    }
  }, [products]);

  const drawer = (
    <Box
      onClick={handleDrawerToggle}
      sx={{
        textAlign: "center",
        height: "100%",
      }}
    >
      <Box
        bgcolor={"#FFE9ED"}
        height={"4rem"}
        width={"100%"}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          px={2}
          width={"100%"}
        >
          <img
            src={logoPng}
            style={{ cursor: "pointer", width: "6rem" }}
            alt=""
          />
          <img src={close} width={30} alt="" style={{ cursor: "pointer" }} />
        </Box>
      </Box>
      <Box>
        <List>
          <ListItem disablePadding>
            <ListItemButton onClick={() => navigate("/hot-deals")}>
              <ListItemText primary={"Hot Deals"} />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton onClick={() => navigate("/shop/187/212")}>
              <ListItemText primary={"Shop"} />
            </ListItemButton>
          </ListItem>
          {categories?.map((data, id) => (
            <div key={id}>
              <ListItem disablePadding>
                <ListItemButton
                  onClick={() => {
                    if (data.ID === openIdx) {
                      setOpenIdx(null);
                    } else {
                      setOpenIdx(data.ID);
                    }
                    setMobileOpen(!mobileOpen);
                  }}
                >
                  <ListItemText
                    primary={data.category}
                    sx={{ fontWeight: 600 }}
                    color={openIdx === data.ID ? "red" : ""}
                  />
                  {openIdx === data.ID ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
              </ListItem>
              <Collapse in={openIdx === data.ID} timeout="auto" unmountOnExit>
                <List sx={{ padding: "0 15px" }}>
                  {data?.SUBCATEGORY.map((item, id) => (
                    <ListItemButton
                      key={id}
                      sx={{ textAlign: "left" }}
                      onClick={() => {
                        navigate(`/shop/${data.ID}/${item.SUBCATGID}`);
                      }}
                    >
                      <ListItemText primary={item.SUBCATG} />
                    </ListItemButton>
                  ))}
                </List>
              </Collapse>
            </div>
          ))}
        </List>
      </Box>
    </Box>
  );
  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <div>
      {/* <AppBar
        position="relative"
        sx={{
          bgcolor: "#FFE9ED",
          height: { md: "3rem", xs: "3rem" }, // "7dvh"
          justifyContent: "center",
          boxShadow: "none",
          padding: { xs: "0 .5rem", md: "0 40px" },
          marginBottom: { xs: "1rem" },
        }}
      >
        <Box
          display={"flex"}
          justifyContent={{ xs: "center", sm: "space-between" }}
          px={{ xs: ".1rem", md: 2 }}
        >
          <Typography
            fontSize={{ xs: ".7rem", sm: ".85rem" }}
            fontWeight={"500"}
          >
            Sparkle more! Get 10% off with code{" "}
            <span style={{ color: "#FF6C86", fontWeight: "500" }}>GLOW10</span>{" "}
            .
          </Typography>
          <Box
            display={{ xs: "none", sm: "flex" }}
            gap={2}
            alignItems={"center"}
          >
            <Box display={"flex"} gap={1} alignItems={"center"}>
              <img src={phoneIcon} alt="" />
              <Typography fontSize={".85rem"} fontWeight={"400"}>
                Get App
              </Typography>
            </Box>
            <Typography fontSize={"1rem"} fontWeight={"400"}>
              |
            </Typography>
            <Typography fontSize={".85rem"} fontWeight={"400"}>
              Help
            </Typography>
          </Box>
        </Box>
      </AppBar> */}

      <AppBar
        component="nav"
        position="relative"
        sx={{
          bgcolor: "#FFF",
          color: "#2D2C2C",
          // height: { md: "6rem", xs: "8rem" }, // { xs: "20vh", sm: "20vh" }
          justifyContent: { xs: "space-around", sm: "space-between" },
          alignItems: { xs: "normal", sm: "normal" },
          padding: { xs: "1rem", md: "1rem 4rem" },
          boxShadow: {
            xs: "none",
            md: "rgba(149, 157, 165, 0.2) 0px 5px 15px",
          },
          zIndex: { xs: 5, md: 100 },
        }}
      >
        <Toolbar
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            paddingX: { xs: 0, sm: 1 },
            height: "100%",
            // paddingTop: { xs: 0, md: "2rem" },
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              height: "100%",
              minWidth: { md: "45%", lg: "30%" },
              justifyContent: "space-between",
              mt: { md: 0, xs: ".5rem" },
            }}
          >
            <IconButton
              size="large"
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{
                mr: { sm: 2 },
                display: { lg: "none" },
                color: "black",
                marginLeft: { xs: "0rem" },
                marginBottom: { xs: "0rem", md: 0 },
                paddingTop: { xs: ".3rem" },
                paddingLeft: { xs: 0 },
              }}
            >
              <img src={menuIcon} alt="" />
            </IconButton>
            <Box
              sx={{
                display: { sm: "flex" },
                width: { md: "8rem", xs: "5.5rem" },
                cursor: "pointer",
              }}
              onClick={() => navigate(routes.home.paths[0])}
            >
              <img src={logoPng} alt="" width={"100%"} height={"100%"} />
            </Box>
            {navItems.map((item, idx) => (
              <Typography
                key={idx}
                display={{ xs: "none", md: "flex" }}
                variant="div"
                fontSize={"1rem"}
                fontWeight={"400"}
                color={"#000"}
              >
                <Link
                  component={RouterLink}
                  to={item.path}
                  style={{ textDecoration: "none" }}
                  color={`#000`}
                >
                  {item.name}
                </Link>
              </Typography>
            ))}
          </Box>
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={{ sm: "space-between", xs: "end" }}
            minWidth={{ sm: "80%", md: "50%" }}
          >
            <Box
              width={{ md: "60%", sm: "65%" }}
              display={{ xs: "none", sm: "block" }}
            >
              <Search sx={{ position: "relative" }}>
                <Paper
                  component={"form"}
                  sx={{
                    borderRadius: 20,
                    border: "1px solid #FF6C86",
                    boxShadow: "none",
                  }}
                >
                  <SearchField
                    id="search"
                    type="search"
                    placeholder="Search for products"
                    className="searchInput"
                    onChange={(e) => {
                      setIsSearching(true);
                      setSearchValue(e.target.value);
                    }}
                    // onBlur={() => setIsSearching(false)}
                    value={searchValue}
                    sx={{
                      width: "100%",
                      borderRadius: 50,
                      bgcolor: "#fff",
                      outline: "none",
                      border: "none",
                      padding: "0",
                      height: "3rem",
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          sx={{ cursor: "pointer" }}
                          onSubmit={(e) => {
                            e.preventDefault();
                            const params = new URLSearchParams();
                            params.set("searchText", searchValue);
                            setSearchValue("");
                            navigate(`/search?${params}`);
                          }}
                        >
                          <IconButton
                            type="submit"
                            onClick={(e) => {
                              e.preventDefault();
                              if (searchValue.length < 2) {
                                return;
                              }
                              const params = new URLSearchParams();
                              params.set("searchText", searchValue);
                              setSearchValue("");
                              setIsSearching(false);
                              navigate(`/search?${params}`);
                            }}
                          >
                            <img src={search} alt="" height={20} />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Paper>

                {isSearching && searchValue.length > 2 && (
                  <Box
                    sx={{
                      width: "100%",
                      minHeight: 200,
                      maxHeight: 300,
                      position: "fixed",
                      borderRadius: 2,
                      paddingX: 2,
                      paddingY: 2,
                      zIndex: 9999,
                      bgcolor: "white",
                      overflow: "scroll",
                      boxShadow: 2,
                      display: `${isSearching ? "flex" : "hidden"}`,
                      gap: 2,
                      flexDirection: "column",
                      marginY: 2,
                    }}
                  >
                    {isSearchResultLoading ? (
                      <Box
                        sx={{
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <CircularProgress size={24} sx={{ color: "#FF6C86" }} />
                      </Box>
                    ) : products && products[0].result === 0 ? (
                      <>
                        <Box
                          sx={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Alert severity="info" sx={{ width: "100%" }}>
                            No result found !{" "}
                          </Alert>
                        </Box>
                      </>
                    ) : (
                      products?.map((it, index) => (
                        <Box
                          key={index}
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            navigate(
                              `/productDetails/${it.PRD_CODE}/${it.SUBCATG}`
                            );
                            setSearchValue("");
                          }}
                        >
                          <Box
                            style={{
                              textDecoration: "none",
                              color: "#FF6B6B",
                            }}
                          >
                            <Typography>{it.PRD_NAME}</Typography>
                          </Box>
                          <Button type="button" variant="text">
                            <ArrowRightAltIcon color="#FF6B6B" />
                          </Button>
                        </Box>
                      ))
                    )}
                  </Box>
                )}
              </Search>
            </Box>
            <Box
              sx={{
                display: { sm: "flex", lg: "flex" },
                height: "100%",
                alignItems: "center",
                minWidth: { md: "20%" },
              }}
            >
              {isAuthenticated ? (
                <Box
                  px={2}
                  height={"100%"}
                  width={"100%"}
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  gap={{ md: 4, xs: 2 }}
                >
                  <Box
                    sx={{ height: { sm: 25, xs: 20 }, cursor: "pointer" }}
                    onClick={() => navigate(routes.wishList.paths[0])}
                  >
                    <img src={wishlistIcon} alt="" height={"100%"} />
                  </Box>
                  <Box
                    sx={{
                      height: { sm: 25, xs: 20 },
                      cursor: "pointer",
                      position: "relative",
                    }}
                    // onClick={() => navigate(routes.cart.paths[0])}
                    onClick={handleShoppingDrawerToggle}
                  >
                    <img src={cartIcon} alt="" height={"100%"} />
                    <Box
                      width={20}
                      height={20}
                      borderRadius={10}
                      display={"flex"}
                      alignItems={"center"}
                      justifyContent={"center"}
                      sx={{
                        position: "absolute",
                        top: -10,
                        right: -8,
                        bgcolor: "#FF6C86",
                      }}
                    >
                      <Typography color={"#FFFFFF"} fontSize={12}>
                        {cartSummary?.CNT ?? 0}
                      </Typography>
                    </Box>
                  </Box>
                  <Box>
                    <Box
                      sx={{ height: { sm: 25, xs: 20 }, cursor: "pointer" }}
                      onClick={(e) => setAnchorEl(e.currentTarget)}
                    >
                      <img src={profileIcon} alt="" height={"100%"} />
                    </Box>
                    <Menu
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl)}
                      onClose={handleDropdownClose}
                      sx={{ mt: ".4rem", minWidth: "25ch" }}
                      elevation={2}
                    >
                      {rightMenuItems.map((dt, id) => (
                        <MenuItem
                          key={id}
                          onClick={handleDropdownClose}
                          sx={{ py: ".35rem", px: ".9rem" }}
                        >
                          <Link
                            component={RouterLink}
                            to={dt.path}
                            color="inherit"
                            sx={{
                              textDecoration: "none",
                              fontWeight: "400",
                              fontSize: "16px",
                              textAlign: "right",
                              width: "100%",
                            }}
                          >
                            {dt.name}
                          </Link>
                        </MenuItem>
                      ))}
                      <MenuItem
                        onClick={handleLogout}
                        sx={{ py: ".35rem", px: ".9rem" }}
                      >
                        <Link
                          color="inherit"
                          sx={{
                            textDecoration: "none",
                            fontWeight: "400",
                            fontSize: "16px",
                            textAlign: "right",
                            width: "100%",
                          }}
                        >
                          Logout
                        </Link>
                      </MenuItem>
                    </Menu>
                  </Box>
                </Box>
              ) : (
                <Box
                  mx={{ md: 0 }}
                  height={"100%"}
                  width={"100%"}
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  paddingRight={{ sx: "2rem" }}
                >
                  <Button
                    onClick={() => navigate(routes.login.paths[0])}
                    variant="contained"
                    sx={{
                      width: "100%",
                      bgcolor: "#FF6C86",
                      borderRadius: "25px",
                      color: "#fff",
                      fontSize: { xs: ".8rem", md: "1rem" },
                      textTransform: "capitalize",
                      px: 3,
                      py: 1,
                      ":hover": {
                        bgcolor: "#FF6C86",
                      },
                      marginRight: {
                        md: 0,
                      },
                    }}
                  >
                    Login/Register
                  </Button>
                </Box>
              )}
            </Box>
          </Box>
        </Toolbar>
        <Toolbar sx={{ display: { sm: "none" }, width: "100%", padding: 0 }}>
          <Search sx={{ position: "relative", padding: 0 }}>
            <Paper
              component={"form"}
              sx={{
                borderRadius: 20,
                border: "1px solid #FF6C86",
                boxShadow: "none",
                width: "100%",
                height: { xs: "3rem" },
              }}
            >
              <SearchField
                id="search"
                type="search"
                placeholder="Search for products"
                className="searchInput"
                // onBlur={() => setIsSearching(false)}
                onChange={(e) => {
                  setIsSearching(true);
                  setSearchValue(e.target.value);
                }}
                value={searchValue}
                sx={{
                  width: "100%",
                  bgcolor: "#fff",
                  outline: "none",
                  border: "none",
                  height: "100%",
                  borderRadius: "25px",
                  appearance: "none",
                  padding: "0",
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      sx={{ borderRadius: "25px" }}
                      onSubmit={(e) => {
                        e.preventDefault();
                        const params = new URLSearchParams();
                        params.set("searchText", searchValue);
                        setSearchValue("");
                        setIsSearching(false);
                        navigate(`/search?${params}`);
                      }}
                    >
                      <IconButton
                        type="submit"
                        onClick={(e) => {
                          e.preventDefault();
                          if (searchValue.length < 2) {
                            return;
                          }
                          const params = new URLSearchParams();
                          params.set("searchText", searchValue);
                          setIsSearching(false);
                          setSearchValue("");
                          navigate(`/search?${params}`);
                        }}
                      >
                        <img src={search} alt="" height={20} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Paper>

            {isSearching && searchValue.length > 2 && (
              <Box
                sx={{
                  width: "100%",
                  minHeight: 200,
                  maxHeight: 300,
                  position: "fixed",
                  borderRadius: 2,
                  paddingX: 2,
                  paddingY: 2,
                  zIndex: 9999,
                  bgcolor: "white",
                  overflow: "scroll",
                  boxShadow: 2,
                  display: `${isSearching ? "flex" : "hidden"}`,
                  gap: 2,
                  flexDirection: "column",
                  marginY: 2,
                }}
              >
                {isSearchResultLoading ? (
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <CircularProgress size={24} sx={{ color: "#FF6C86" }} />
                  </Box>
                ) : products && products[0].result === 0 ? (
                  <>
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Alert severity="info" sx={{ width: "100%" }}>
                        No result found !{" "}
                      </Alert>
                    </Box>
                  </>
                ) : (
                  products?.map((it, index) => (
                    <Box
                      key={index}
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        navigate(
                          `/productDetails/${it.PRD_CODE}/${it.SUBCATG}`
                        );
                        setSearchValue("");
                      }}
                    >
                      <Box
                        style={{
                          textDecoration: "none",
                          color: "#FF6B6B",
                        }}
                      >
                        <Typography>{it.PRD_NAME}</Typography>
                      </Box>
                      <Button type="button" variant="text">
                        <ArrowRightAltIcon color="#FF6B6B" />
                      </Button>
                    </Box>
                  ))
                )}
              </Box>
            )}
          </Search>
        </Toolbar>
      </AppBar>
      {/* <QuickLinks /> */}
      <nav>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", lg: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: { xs: 280, sm: 280 },
            },
          }}
        >
          {drawer}
        </Drawer>
      </nav>
      <ShoppingDrawer
        container={container}
        shoppingCartOpen={shoppingCartOpen}
        handleShoppingDrawerToggle={handleShoppingDrawerToggle}
      />
    </div>
  );
};

export default Navbar;

const navItems = [
  {
    name: "Home",
    path: "/",
  },
  {
    name: "Hot Deals",
    path: "/hot-deals",
  },
  {
    name: "Shop",
    path: "/shop/187/212",
  },
];

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  // marginRight: theme.spacing(2),
  // marginLeft: theme.spacing(2),
  width: "100%",
  transform: "scale(1)",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "100%",
    transform: "scale(1)",
  },
  // [theme.breakpoints.up("md")]: {
  //   width: "45ch",
  // },
}));

export const SearchField = styled(TextField)(() => ({
  "& fieldset": {
    border: "none", // Remove the border
    padding: "0",
  },
  "&:hover fieldset": {
    padding: "0",
    border: "none", // Remove the border on hover
  },
  "&.Mui-focused fieldset": {
    border: "none", // Remove the border when focused
    padding: "0",
  },
  "& MuiInputBase-input": {
    padding: "0 !important",
  },
}));

const rightMenuItems = [
  {
    name: "Dashboard",
    path: "/profile/dashboard",
  },
  {
    name: "Orders",
    path: "/order/Pending",
  },
  // {
  //   name: "Downloads",
  //   path: "/profile/download",
  // },
  {
    name: "Address",
    path: "/profile/address",
  },
  {
    name: "Account Details",
    path: "/profile/account",
  },
];
