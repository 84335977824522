import { Box, Button, IconButton, Typography } from "@mui/material";
import React, { useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import productImg from "../../assets/Cart/product.png";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import deleteIcon from "../../assets/Cart/deleteIcon.svg";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import api from "../../api/api";
import { createPromiseToast } from "../../utils/createPromiseToast";
import DeleteProductModal from "../Modal/DeleteProductModal";

const ShoppingCartDrawerCard = ({ data, editCartMutate }) => {
  const queryClient = useQueryClient();
  const [openModal, setOpenModal] = useState(false);

  const handleDecreaseQuantity = () => {
    if (parseInt(data?.QUANTITY) > 1) {
      let values = {
        prd_code: data?.PRD_CODE,
        quantity: parseInt(data?.QUANTITY) - 1,
        rate: data?.RATE,
      };
      editCartMutate({ billno: data?.BILLNO, data: values });
    }
  };

  const handleIncreaseQuantity = () => {
    if (parseInt(data?.QUANTITY) > 0) {
      let values = {
        prd_code: data?.PRD_CODE,
        quantity: parseInt(data?.QUANTITY) + 1,
        rate: data?.RATE,
      };
      editCartMutate({ billno: data?.BILLNO, data: values });
    }
  };

  return (
    <Box
      display={"flex"}
      justifyContent={"space-between"}
      borderRadius={2}
      p={2}
      sx={{
        boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
      }}
      gap={1}
    >
      <Box width={{ xs: "6rem" }} maxHeight={{ md: "8rem" }} minWidth={"3rem"}>
        <LazyLoadImage
          src={data?.TIMG}
          effect="blur"
          width={"100%"}
          height={"100%"}
          style={{ objectFit: "contain" }}
        />
      </Box>
      <Box minWidth={{ xs: 170, md: 300 }}>
        <Typography
          variant="p"
          component="div"
          sx={{
            color: "#858D97",
            fontWeight: 400,
            // marginX: 2,
            fontSize: { xs: ".8rem", md: ".8rem" },
            textAlign: "start",
          }}
        >
          Product ID: {data?.PRD_CODE}
        </Typography>
        <Typography
          variant="p"
          component="div"
          sx={{
            color: "#2D2C2C",
            fontWeight: 500,
            // marginX: 2,
            fontSize: { xs: ".8rem", sm: ".8rem", md: "1rem" },
            textAlign: "start",
          }}
        >
          {data?.PRD_NAME}
        </Typography>
        <Typography
          variant="p"
          component="div"
          sx={{
            color: "#2D2C2C",
            fontWeight: 500,
            // marginX: 2,
            fontSize: { xs: ".8rem", sm: ".8rem", md: "1rem" },
            textAlign: "start",
            paddingY: { xs: ".5rem" },
          }}
        >
          {`₹ ${data?.RATE}`}
        </Typography>
        <Box sx={{ display: "flex", placeItems: "center" }}>
          <Button
            onClick={handleDecreaseQuantity}
            disabled={data?.QUANTITY === "1"}
            sx={{
              color: "black",
              backgroundColor: "#F2F4F9",
              "&:hover": {
                backgroundColor: "#FF6C86",
              },
              borderRadius: 2,
              minWidth: "auto",
              minHeight: "auto",
              aspectRatio: 1,
            }}
          >
            <RemoveIcon fontSize="1rem" />
          </Button>
          <Typography
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "2rem",
              aspectRatio: 1,
              backgroundColor: "#fff",
            }}
          >
            {data?.QUANTITY}
          </Typography>
          <Button
            onClick={handleIncreaseQuantity}
            sx={{
              color: "black",
              // fontSize: "2rem",
              backgroundColor: "#F2F4F9",
              "&:hover": {
                backgroundColor: "#FF6C86",
              },
              borderRadius: 2,
              minWidth: "auto",
              minHeight: "auto",
              // aspectRatio: 1,
            }}
          >
            <AddIcon fontSize="1rem" />
          </Button>
        </Box>
      </Box>
      <Box>
        <IconButton
          size="large"
          //   edge="end"
          aria-haspopup="true"
          // onClick={handleProfileMenuOpen}
          sx={{
            // bgcolor: "#0281C6",
            borderRadius: 2,
            "&:hover": {
              //   background: "#0281C6",
            },
          }}
          //   onClick={onDelete}
          // onClick={handleDeleteFromCart}
          onClick={() => setOpenModal(true)}
        >
          <img src={deleteIcon} alt="" width={15} />
        </IconButton>
      </Box>
      <DeleteProductModal
        open={openModal}
        handleClose={() => setOpenModal(false)}
        billno={data?.BILLNO}
      />
    </Box>
  );
};

export default ShoppingCartDrawerCard;
