import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { useMediaQuery } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

export default function HorizontalLinearStepper({ steps, activeStep }) {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("sm"));

  return (
    <Box sx={{ width: "100%", paddingLeft: { xs: "2rem", md: 0 } }}>
      <Stepper
        activeStep={activeStep}
        alternativeLabel={matches}
        orientation={matches ? "horizontal" : "vertical"}
      >
        {steps?.map((label, index) => {
          const isCompleted = index < activeStep;

          return (
            <Step key={label}>
              <StepLabel
                sx={{
                  ".MuiStepLabel-label": {
                    color: isCompleted ? "#66D97F" : "inherit",
                    fontWeight: isCompleted ? "bold" : "normal",
                  },
                  ".MuiStepIcon-root": {
                    color: isCompleted ? "#66D97F" : "inherit",
                  },
                }}
                StepIconComponent={() =>
                  isCompleted ? (
                    <CheckCircleIcon sx={{ color: "green" }} />
                  ) : (
                    <Box
                      sx={{
                        width: 24,
                        height: 24,
                        borderRadius: "50%",
                        backgroundColor: "inherit",
                        border: "2px solid currentColor",
                      }}
                    />
                  )
                }
              >
                {label}
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>
    </Box>
  );
}
